import '@react-pdf-viewer/core/lib/styles/index.css'

import {
  Box,
  Button,
  Center,
  Grid,
  Image,
  RadioGroup,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { LocalizationMap, Viewer } from '@react-pdf-viewer/core'
import jp_JP from '@react-pdf-viewer/locales/lib/jp_JP.json'
import { CsbFormControl, CsbHeader } from '@src/components'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { CsbQuestionRadio } from '@src/components/CsbQuestionRadio'
import { CsbVimeo } from '@src/components/CsbVimeo/CsbVimeo'
import Embed from '@src/components/Embed'
import { useLearningHooks } from '@src/components/pages/learing/[id]/hooks'
import {
  Correct,
  LearningAnswerInput,
  Question as QuestionType,
} from '@src/graphql/generated/graphql'
import { AdminResearchAnswerResult } from '@src/models/Learning/components/AdminResearchAnswerResult'
import { LearningAnswerResult } from '@src/models/Learning/components/LearningAnswerResult'
import { ResearchAnswerResult } from '@src/models/Learning/components/ResearchAnswerResult'
import { memo, useCallback, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type QuestionPropsType = {
  findError: (attributeName: string) => string
  form: UseFormReturn<LearningAnswerInput>
  index: number
  question: Pick<
    QuestionType,
    'uuid' | 'name' | 'answerType' | 'explanation'
  > & {
    corrects: Pick<Correct, 'name' | 'uuid'>[]
  }
}

const Question = ({ findError, form, index, question }: QuestionPropsType) => {
  //現在のラジオボタンの値を管理するstate
  const [value, setValue] = useState<string | null>(null)

  //radioボタンが変わるたびにvalueを更新する
  const onChange = (nextValue: string) => {
    setValue(nextValue)
    form.setValue(`answers.${index}.correctUuid` as const, nextValue)
  }

  return (
    <Box>
      <Text fontWeight={'bold'} mb={3}>
        <CsbNewLineText text={question.name} />
      </Text>
      <CsbFormControl errorText={findError(`answers.${index}`)}>
        <RadioGroup
          style={{ display: 'flex', flexDirection: 'column' }}
          value={value ?? ''}
          onChange={onChange}
        >
          <Grid gap={4} gridColumn={'1fr'}>
            {question.corrects.map((correct) => (
              <CsbQuestionRadio
                isChecked={correct.uuid === value}
                key={correct.uuid}
                value={correct.uuid}
              >
                {correct.name}
              </CsbQuestionRadio>
            ))}
          </Grid>
        </RadioGroup>
      </CsbFormControl>
    </Box>
  )
}

type LearnAccessAnalyticsPropsType = {
  shortPath: string
}

const LearnAccessAnalytics = ({ shortPath }: LearnAccessAnalyticsPropsType) => {
  // 画像からのアクセスをやめ、直接アクセスするよう変更
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/an/${shortPath}/learning_access?json=1`,
      {
        method: 'GET',
      }
    ).then()
  }, [shortPath])
  return <></>
}

//学習ページのcomponent
export const Learning = memo(() => {
  const {
    adminAnswerResearches,
    adminResearchQuestions,
    answerResearches,
    csbVimeo,
    data,
    errors,
    form,
    id,
    learningResult,
    loading,
    onLearnComplete,
    onNavigateThanksPage,
    onSubmit,
    researchQuestions,
  } = useLearningHooks()

  const { t } = useTranslation()
  useEffect(() => {
    if (data?.learning.phishingEmail.originalLearningUrl) {
      window.location.href = data?.learning.phishingEmail.originalLearningUrl
    }
  }, [data?.learning.phishingEmail.originalLearningUrl])

  const isAnsweredAdminResearch =
    (adminAnswerResearches.data?.adminAnswerResearches.length ?? 0) > 0

  const [isFocused, setIsFocused] = useState(false)

  const findError = useCallback(
    (attributeName: string) => {
      const error = errors.find(
        (error) => error?.extensions?.attribute === attributeName
      )
      return error?.message ?? ''
    },
    [errors]
  )

  // 最初のエラーのindexを取得
  const findErrorIndex = useCallback(() => {
    return (
      (data?.learning.learn?.questions ?? []).findIndex((_, i) => {
        const message = findError(`answers.${i}`)
        return message !== ''
      }) ?? -1
    )
  }, [data?.learning.learn?.questions, findError])

  useEffect(() => {
    if (isFocused) {
      return
    }
    const errorIndex = findErrorIndex()
    if (errorIndex !== -1) {
      const element = document.getElementById(`question${errorIndex}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
      setIsFocused(true)
    }
  }, [isFocused, findErrorIndex])

  return (
    <>
      {data &&
      !loading &&
      !data?.learning.phishingEmail.originalLearningUrl &&
      data.learning.phishingEmail.isDisplayLogoInLearning ? (
        <CsbHeader />
      ) : (
        ''
      )}

      <Box maxW={'800px'} mx={'auto'} px={'14px'} py={20}>
        <LearnAccessAnalytics shortPath={id} />
        <Grid rowGap={10}>
          {data?.learning.phishingEmail.learningContentComment && (
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.learning.phishingEmail.learningContentComment,
              }}
            />
          )}

          {data &&
            !loading &&
            !data?.learning.phishingEmail.originalLearningUrl && (
              <>
                {data.learning.movie && (
                  <CsbVimeo
                    video={data.learning.movie.url}
                    {...csbVimeo}
                    width={890}
                  />
                )}
                {data.learning.comic && (
                  <Box>
                    <Grid rowGap={10}>
                      {data.learning.comic.images.map((image, index) => (
                        <Box key={`${image.uuid}_${index}`}>
                          <Image
                            fallback={
                              <Center bgColor={'gray.100'} h={520}>
                                <Box h={4} w={4}>
                                  <Spinner
                                    color="blue.500"
                                    emptyColor="gray.200"
                                    size="xl"
                                    speed="0.65s"
                                    thickness="4px"
                                  />
                                </Box>
                              </Center>
                            }
                            src={image.url}
                            w={'full'}
                          />
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                )}

                {data.learning.pdf?.url ? (
                  <Box>
                    <Box borderWidth={1} height={600} mb={4}>
                      <Viewer
                        fileUrl={data.learning.pdf?.url}
                        localization={jp_JP as unknown as LocalizationMap}
                      />
                    </Box>
                    <Center>
                      <Button
                        variant="outline"
                        onClick={() => {
                          window.open(data.learning.pdf?.url, '_blank')
                        }}
                      >
                        PDFをダウンロード
                      </Button>
                    </Center>
                  </Box>
                ) : (
                  ''
                )}

                {data.learning.phishingEmail.pdfLink ? (
                  <Embed html={data.learning.phishingEmail.pdfLink} />
                ) : (
                  ''
                )}

                {data.learning.learn && (
                  <>
                    {data?.learning.phishingEmail.learningComment && (
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: data?.learning.phishingEmail.learningComment,
                        }}
                      />
                    )}

                    {data.learning.isLearned ? (
                      <Box>
                        <Text color={'teal'} fontSize={14} fontWeight={'bold'}>
                          {t('learn.alreadyAnswered')}
                        </Text>
                        <Box mt={3}>
                          <LearningAnswerResult {...learningResult} />
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <Grid rowGap={10}>
                            {data.learning.learn.questions.map(
                              (question, index) => (
                                <Box key={`${question.uuid}_${index}`}>
                                  <div id={`question${index}`}>
                                    <Question
                                      findError={findError}
                                      form={form}
                                      index={index}
                                      key={`${question.uuid}_${index}`}
                                      question={question}
                                    />
                                  </div>
                                </Box>
                              )
                            )}
                          </Grid>
                          <Center>
                            <Button
                              mt={8}
                              size={'lg'}
                              onClick={() => {
                                setIsFocused(false)
                                onSubmit().then()
                              }}
                            >
                              {t('learn.answer')}
                            </Button>
                          </Center>
                        </Box>
                      </>
                    )}
                  </>
                )}
                {data.learning.isAnsweredResearches && (
                  <>
                    <Box>
                      <Text color={'teal'} fontSize={14} fontWeight={'bold'}>
                        {t('research.result.contentTitle')}
                      </Text>
                      <ResearchAnswerResult
                        answerResearches={
                          answerResearches.data?.answerResearches ?? []
                        }
                        researchQuestions={
                          researchQuestions.data?.researchQuestions ?? []
                        }
                      />
                    </Box>
                    {isAnsweredAdminResearch && (
                      <Box>
                        <Text color={'teal'} fontSize={14} fontWeight={'bold'}>
                          {t('research.result.adminContentTitle')}
                        </Text>
                        <AdminResearchAnswerResult
                          answerResearches={
                            adminAnswerResearches.data?.adminAnswerResearches ??
                            []
                          }
                          researchQuestions={
                            adminResearchQuestions.data
                              ?.adminResearchQuestions ?? []
                          }
                        />
                      </Box>
                    )}
                  </>
                )}

                {data.learning.isLearned &&
                  data.learning.hasResearch &&
                  !data.learning.isAnsweredResearches && (
                    <Center>
                      <Button
                        mt={8}
                        size={'lg'}
                        onClick={() => onNavigateThanksPage(true)}
                      >
                        {t('learn.gotoQuestionnaire')}
                      </Button>
                    </Center>
                  )}

                {!data.learning.isLearned && !data.learning.learn && (
                  <Center>
                    <Button mt={8} size={'lg'} onClick={onLearnComplete}>
                      {t('learn.completeLearning')}
                    </Button>
                  </Center>
                )}
              </>
            )}
        </Grid>
      </Box>
    </>
  )
})
