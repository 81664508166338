import {
  Alert,
  AlertIcon,
  AspectRatio,
  AspectRatioProps,
  Box,
  Spinner,
} from '@chakra-ui/react'
import Vimeo from '@u-wave/react-vimeo'
import { ComponentProps, useEffect, useState } from 'react'

export const useCsbVimeo = () => {
  const [vimeoState, setVimeoState] = useState({
    error: false,
    loading: false,
  })
  return { setVimeoState, vimeoState }
}

type UseCsbVimeo = typeof useCsbVimeo
type UseCsbVimeoResult = ReturnType<UseCsbVimeo>

type CsbVimeoProps = Omit<AspectRatioProps, 'children'> &
  UseCsbVimeoResult &
  Pick<ComponentProps<typeof Vimeo>, 'video'> &
  Pick<ComponentProps<typeof Vimeo>, 'width'>

/**
 * vimeoの動画はこれに差し替える
 */
export const CsbVimeo = ({
  setVimeoState,
  video,
  vimeoState,
  width = '640',
  ...props
}: CsbVimeoProps) => {
  const [textTrack, setTextTrack] = useState<string>('')
  useEffect(() => {
    const urlParams = new URLSearchParams(new URL(video.toString()).search)
    const textTrackParam = urlParams.get('texttrack')
    const browserLanguage = navigator?.language || ''

    if (textTrackParam === 'auto') {
      setTextTrack(browserLanguage)
    } else {
      setTextTrack(textTrackParam || '')
    }
  }, [video])

  return (
    <AspectRatio
      bgColor={'gray.100'}
      mx={'auto'}
      ratio={16 / 9}
      w={'full'}
      {...props}
    >
      <>
        <Box h={4} w={4}>
          <Spinner
            color="blue.500"
            emptyColor="gray.200"
            size="xl"
            speed="0.65s"
            thickness="4px"
          />
        </Box>
        <Vimeo
          key={textTrack}
          speed={true}
          textTrack={textTrack}
          video={video}
          width={width}
          onError={(error) =>
            setVimeoState((prevState) => ({
              ...prevState,
              error: !!error,
            }))
          }
          onReady={() =>
            setVimeoState((prevState) => ({
              ...prevState,
              loading: false,
            }))
          }
        />
        {vimeoState.error && (
          <Alert status="error">
            <AlertIcon />
            プレビューを表示できません。
            <br />
            vimeoの閲覧権限を確認してください
          </Alert>
        )}
      </>
    </AspectRatio>
  )
}
